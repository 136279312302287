import React from 'react'

import Layout from '../../Layout'
import SEO from '../../SEO'

import { Container, Title, P } from '../../components/Legals'

const Legal = () => (
  <Layout withPadding={false} restricted={false}>
    <SEO title="Mentions légales" />
    <Container>
      <P><Title>MENTIONS LÉGALES</Title></P>
      <div>Association Chicharro</div>
      <div>650 chemin des Sigauds</div>
      <div>13560 Sénas</div>
      <div>France</div>
      <div />
      <div>chicharro@laposte.net</div>
      <div />
      <div>n° SIREN : 891692394</div>
      <div />
      <div />
      <P>Site web développé par Robel Tekle Haimanot</P>
      <P>Photos d&apos;accueil par Andy (www.instagram.com/andyledidi)</P>
      <div>Site hébergé par Netlify Inc.</div>
      <div>2325 3rd Street, Suite 296</div>
      <div>San Francisco, California 94107</div>
      <div>USA</div>
      <P> </P>
    </Container>
  </Layout>
)

export default Legal
